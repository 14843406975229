#index {
  position: relative;

  & section {
    margin-bottom: 150px;
  }

  & .hero {
    height: 600px;

    & .content {
      position: relative;
      height: 600px;
      // background-image: url("../../images/homepage/desktop/image-homepage-hero.jpg");
      // background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      // & .image {
      // height: 100%;
      // width: 100%;

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
      // }

      & .text {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #fff;
        padding-right: 3.5rem;
        padding-top: 3.5rem;
        width: 445px;

        & h1 {
          margin-bottom: 48px;
        }
      }
    }
  }

  .about {
    & .content {
      display: flex;
      height: 600px;

      & .text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 350px;
        height: 100%;
        margin: 0 auto;
        padding: 0 1rem;

        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;

        & div {
          margin: 1rem 0;
          font-size: 16px;
        }

        & h1 {
          // margin-bottom: 48px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #index {
    // position: relative;

    & section {
      // margin-bottom: 150px;
    }

    & .hero {
      // height: 600px;

      & .content {
        // position: relative;
        // height: 600px;
        // background-image: url("../../images/homepage/desktop/image-homepage-hero.jpg");
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: cover;

        & .text {
          // position: absolute;
          // left: 0;
          // bottom: 0;
          // background-color: #fff;
          // padding-right: 56px;
          // padding-top: 56px;
          // width: 445px;

          & h1 {
            margin-bottom: 2rem;
            font-size: 40px;
          }
        }
      }
    }

    .about {
      & .content {
        // display: flex;
        // height: 600px;

        & .image {
          object-fit: cover;
          object-position: 75%;
          width: 281px;
        }

        & .text {
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
          // max-width: 350px;
          // height: 100%;
          // margin: 0 auto;
          // padding: 0 1rem;

          // border-top: 1px solid $light-grey;
          // border-bottom: 1px solid $light-grey;

          & div {
            // margin: 1rem 0;
          }

          & h1 {
            // margin-bottom: 48px; //
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  #index {
    // position: relative;

    & section {
      margin-bottom: 5rem;
    }

    & .hero {
      // height: 600px;
      height: auto;

      & .content {
        // position: relative;
        // height: 600px;
        height: auto;
        // background-image: url("../../images/homepage/desktop/image-homepage-hero.jpg");
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: cover;

        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }

        & .text {
          // position: absolute;
          position: relative;
          // left: 0;
          // bottom: 0;
          // background-color: #fff;
          background-color: transparent;
          // padding-right: 56px;
          // padding-top: 56px;
          // width: 445px;
          width: auto;
          padding: 0;

          & h1 {
            // margin-bottom: 2rem;
            font-size: 40px;
          }
        }
      }
    }

    .about {
      & .content {
        // display: flex;
        height: auto;
        flex-direction: column;

        & .image {
          object-fit: cover;
          object-position: center;
          // width: 281px;
          width: 100%;
        }

        & .text {
          padding: 2rem 0;
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
          // max-width: 350px;
          // height: 100%;
          // margin: 0 auto;
          // padding: 0 1rem;

          // border-top: 1px solid $light-grey;
          // border-bottom: 1px solid $light-grey;

          & div {
            // margin: 1rem 0;
          }

          & h1 {
            // margin-bottom: 48px; //
          }
        }
      }
    }
  }
}
