.header {
  position: relative;
  padding: 3rem 0;
  margin-bottom: 2rem;

  & .container {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;

    & .logo {
      margin-right: auto;
    }

    & .nav-links {
      list-style: none;
      display: flex;
      gap: 3rem;

      & li {
        & a {
          color: $grayish-dark-blue;
        }
        & .active {
          color: $slightly-desaturated-cyan;
        }
      }
    }

    & .hamburger {
      display: none;
    }

    & .mobile-menu {
      position: absolute;
      right: 30px;
      top: 100px;
      background-color: $grayish-dark-blue;
      padding: 2rem 4rem;
      z-index: 5;
      display: none;

      & ul {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 1rem;

        & a {
          color: white;
          text-transform: uppercase;
          text-align: center;
          font-size: 12px;
          font-family: "Public Sans";
          font-weight: thin;
          letter-spacing: 2px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    // padding: 4rem 0;
    margin-bottom: 1rem;

    & .container {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;

      & .logo {
        // margin-right: auto;
      }

      & .nav-links {
        display: none;
        // list-style: none;
        // display: flex;
        // gap: 3rem;

        & li {
          & a {
            // color: $grayish-dark-blue;
          }

          & .active {
            // color: $slightly-desaturated-cyan;
          }
        }
      }

      & .hamburger {
        display: block;
      }

      & .mobile-menu {
        display: block;
      }
    }
  }
}
