$black: #000;
$white: #fff;

// Primary
$slightly-desaturated-cyan: #5fb4a2;
$dark-blue: #203a4c;
$grayish-dark-blue: #33323d;

// Secondary
$very-light-grey: #fafafa; //BG
$light-grey: #eaeaeb; // Text Field
$bright-red: #f43030; // Errors
