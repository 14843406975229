footer {
  background-color: $grayish-dark-blue;
  padding: 2rem 0;
  color: #fff;

  & ul {
    display: flex;
    gap: 3rem;

    & li {
      & a {
        color: #fff;
        line-height: normal;
      }
    }
  }
}

@media (max-width: 375px) {
  footer {
    // background-color: $grayish-dark-blue;
    // padding: 2rem 0;
    // color: #fff;

    & ul {
      // display: flex;
      gap: 2rem;
      flex-direction: column;
      text-align: center;

      & li {
        & a {
          // color: #fff;
          // line-height: normal;
        }
      }
    }
  }
}
