#portfolio {
  .project {
    display: flex;
    margin-bottom: 80px;
    height: 500px;

    & .text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 350px;
      height: 100%;
      margin: 0 auto;
      padding: 0 1rem;
      font-size: 15px;
      border-top: 1px solid $light-grey;
      border-bottom: 1px solid $light-grey;

      & div {
        margin: 1rem 0;
      }
    }

    & .image {
      display: flex;
      align-items: center;

      & img {
        height: 100%;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
}

@media (max-width: 950px) {
  #portfolio {
    .project {
      // display: flex;
      // margin-bottom: 80px;
      height: 400px;

      & .text {
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
        // max-width: 350px;
        // height: 100%;
        // margin: 0 auto;
        // padding: 0 1rem;

        // border-top: 1px solid $light-grey;
        // border-bottom: 1px solid $light-grey;

        & div {
          // margin: 1rem 0;
        }
      }

      & .image {
        // display: flex;
        // align-items: center;

        & img {
          // height: 100%;
        }
      }

      &:nth-child(even) {
        // flex-direction: row-reverse;
      }
    }
  }
}

@media (max-width: 865px) {
  #portfolio {
    .project {
      // display: flex;
      // margin-bottom: 80px;
      // height: 300px;

      & .text {
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
        width: 281px;
        // height: 100%;
        margin: 0;
        margin-left: auto;
        padding: 0;

        // border-top: 1px solid $light-grey;
        // border-bottom: 1px solid $light-grey;

        & div {
          // margin: 1rem 0;
        }
      }

      & .image {
        // display: flex;
        // align-items: center;

        & img {
          height: 350px;
        }
      }

      &:nth-child(even) {
        // flex-direction: row-reverse;

        & .text {
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
          width: 281px;
          // height: 100%;
          margin: 0;
          margin-right: auto;
          padding: 0;

          // border-top: 1px solid $light-grey;
          // border-bottom: 1px solid $light-grey;

          & div {
            // margin: 1rem 0;
          }
        }
      }
    }
  }
}

@media (max-width: 725px) {
  #portfolio {
    .project {
      // display: flex;
      // margin-bottom: 80px;
      // height: 300px;

      & .text {
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
        // width: 281px;
        // height: 100%;
        // margin: 0;
        // margin-left: auto;
        // padding: 0;

        // border-top: 1px solid $light-grey;
        // border-bottom: 1px solid $light-grey;

        & div {
          // margin: 1rem 0;
        }
      }

      & .image {
        // display: flex;
        // align-items: center;

        & img {
          height: 300px;
        }
      }

      &:nth-child(even) {
        // flex-direction: row-reverse;

        & .text {
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
          // width: 281px;
          // height: 100%;
          // margin: 0;
          // margin-right: auto;
          // padding: 0;

          // border-top: 1px solid $light-grey;
          // border-bottom: 1px solid $light-grey;

          & div {
            // margin: 1rem 0;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  #portfolio {
    .project {
      // display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
      height: auto;

      & .text {
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
        width: 100%;
        max-width: 100%;
        // height: 100%;
        margin: 0;
        margin-top: 2rem;
        // margin-left: auto;
        padding: 2rem 0;

        // border-top: 1px solid $light-grey;
        // border-bottom: 1px solid $light-grey;

        & div {
          // margin: 1rem 0;
        }
      }

      & .image {
        // display: flex;
        // align-items: center;
        width: 100%;

        & img {
          height: auto;
          width: 100%;
        }
      }

      &:nth-child(even) {
        flex-direction: column;

        & .text {
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
          width: 100%;
          max-width: 100%;
          // height: 100%;
          margin: 0;
          margin-top: 2rem;
          // margin-left: auto;
          padding: 2rem 0;

          // border-top: 1px solid $light-grey;
          // border-bottom: 1px solid $light-grey;

          & div {
            // margin: 1rem 0;
          }
        }
      }
    }
  }
}
