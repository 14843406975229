@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;500;600;700&family=Public+Sans:ital,wght@0,500;1,500&display=swap");

body,
html {
  font-family: "Ibarra Real Nova", BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.btn {
  font-family: "Public Sans";
}

body {
  font-family: "Public Sans";
  font-weight: 400;
  line-height: 30px;
}

body.one {
  font-size: 16px;
}

body.two {
  font-size: 15px;
}

h1 {
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -0.45px;
  font-weight: bold;
  font-family: "Ibarra Real Nova";
}

h2 {
  font-size: 40px;
  line-height: 42px;
  letter-spacing: -0.36px;
  font-weight: bold;
  font-family: "Ibarra Real Nova";
}

h3 {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.29px;
  font-weight: 400;
  font-family: "Ibarra Real Nova";
}
