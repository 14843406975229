#contact {
  #get-in-touch {
    margin-bottom: 2rem;

    & .container {
      display: flex;

      & h2,
      & div {
        padding-top: 2rem;
        border-top: 1px solid $light-grey;
        width: 50%;
      }

      & div {
        font-size: 15px;
      }
    }
  }

  #form {
    margin-bottom: 8rem;

    & .container {
      display: flex;

      & h2,
      & form {
        border-top: 1px solid $light-grey;
        padding-top: 2rem;
        width: 50%;
      }

      & form {
        & label {
          color: $grayish-dark-blue;
          display: flex;
          flex-direction: column;
          margin-bottom: 2rem;

          & input,
          & textarea {
            background-color: rgba($grayish-dark-blue, 0.1);
            border: none;
            padding: 1rem;
            margin-top: 0.75rem;

            &:active,
            &:focus {
              outline: 1px solid $slightly-desaturated-cyan;
            }

            &:visited:invalid {
              outline: 1px solid $bright-red;
            }

            &::placeholder {
              color: rbga($grayish-dark-blue, 0.3);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #contact {
    #get-in-touch {
      margin-bottom: 3rem;

      & .container {
        // border-top: 1px solid $light-grey;
        // padding-top: 2rem;
        display: block;

        & h2,
        & div {
          // padding-top: 2rem;
          border-top: none;
          width: 100%;
        }

        & h2 {
          border-top: 1px solid $light-grey;
          // margin-bottom: 2rem;
        }

        & div {
          padding-top: 1rem;
        }
      }
    }

    #form {
      // margin-bottom: 8rem;

      & .container {
        // border-top: 1px solid $light-grey;
        // padding-top: 2rem;
        display: block;

        & h2,
        & form {
          // padding-top: 2rem;
          border-top: none;
          width: 100%;
        }

        & h2 {
          border-top: 1px solid $light-grey;
          // margin-bottom: 2rem;
        }

        & form {
          & label {
            // color: $grayish-dark-blue;
            // display: flex;
            // flex-direction: column;
            // margin-bottom: 2rem;

            & input,
            & textarea {
              // background-color: rgba($grayish-dark-blue, 0.1);
              // border: none;
              // padding: 1rem;
              // margin-top: 0.75rem;

              &:active,
              &:focus {
                // outline: 1px solid $slightly-desaturated-cyan;
              }

              &:visited:invalid {
                // outline: 1px solid $bright-red;
              }

              &::placeholder {
                // color: rbga($grayish-dark-blue, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
