#project {
  & .hero {
    & .container {
      & img {
        max-width: 100%;
      }
    }
  }

  & .details {
    margin-top: 4rem;
    // margin-top: 7rem;

    & .container {
      display: flex;
      gap: 1rem;

      & .description {
        max-width: 350px;
        margin-right: auto;
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
        padding: 3rem 0;
        height: max-content;

        // Project Title
        & h2 {
          margin-bottom: 1rem;
        }

        // What was learned
        & p {
          font-size: 15px;
          margin-bottom: 1.5rem;
        }

        // Tech Stack
        & span {
          display: block;
          color: $slightly-desaturated-cyan;
          font-size: 16px;
        }

        & .btn {
          margin-top: 1.5rem;
        }
      }

      & .preview {
        max-width: 625px;

        & h3 {
          font-weight: normal;
          margin-bottom: 2rem;
        }

        & .background {
          padding-top: 2rem;
          & p {
            font-size: 15px;
          }
        }

        & .preview-images {
          & h3 {
            margin-top: 3rem;
          }

          & img {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  & .prev-next {
    margin-top: 3rem;

    & .container {
      display: flex;

      & .link {
        padding: 3rem 0;
        width: 50%;
        display: flex;
        align-items: center;
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;

        & svg {
          margin-right: 2rem;
        }

        & h3 {
          color: $grayish-dark-blue;
        }

        & div {
          color: rgba($grayish-dark-blue, 0.7);
        }

        &:first-child {
          border-right: 1px solid $light-grey;
        }

        &:nth-child(2) {
          a {
            margin-left: auto;
            text-align: right;
          }

          & svg {
            margin: 0;
            margin-left: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #project {
    & .hero {
      & .container {
        & img {
          // max-width: 100%;
        }
      }
    }

    & .details {
      // margin-top: 7rem;

      & .container {
        // display: flex;
        flex-direction: column;

        & .description {
          // max-width: 350px;
          // margin-right: auto;
          // border-top: 1px solid $light-grey;
          // border-bottom: 1px solid $light-grey;
          // padding: 3rem 0;
          // height: max-content;

          display: grid;
          max-width: 100%;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 4fr;
          gap: 1rem;

          // Project Title
          & h2 {
            // margin-bottom: 1rem;
            margin: 0;
          }

          // What was learned
          & p {
            // font-size: 15px;
            // margin-bottom: 1.5rem;
            grid-column: 2;
            grid-row: 1 / span 4;
            margin: 0;
          }

          // Tech Stack
          & span {
            // display: block;
            // color: $slightly-desaturated-cyan;
            // font-size: 16px;
            grid-column: 1;
          }

          & .btn {
            // margin-top: 1.5rem;
            grid-column: 1;
            margin: 0;
          }
        }

        & .preview {
          // max-width: 625px;
          max-width: 100%;

          & h3 {
            // font-weight: normal;
            // margin-bottom: 2rem;
          }

          & .background {
            & p {
              // font-size: 15px;
            }
          }

          & .preview-images {
            & h3 {
              // margin-top: 3rem;
            }

            & img {
              width: 100%;
              // margin-bottom: 1rem;
            }
          }
        }
      }
    }

    & .prev-next {
      // margin-top: 3rem;

      & .container {
        // display: flex;

        & .link {
          // padding: 3rem 0;
          // width: 50%;
          // display: flex;
          // align-items: center;
          // border-top: 1px solid $light-grey;
          // border-bottom: 1px solid $light-grey;

          & svg {
            // margin-right: 2rem;
          }

          & h3 {
            // color: $grayish-dark-blue;
          }

          & div {
            // color: rgba($grayish-dark-blue, 0.7);
          }

          &:first-child {
            // border-right: 1px solid $light-grey;
          }

          &:nth-child(2) {
            a {
              // margin-left: auto;
              // text-align: right;
            }

            & svg {
              // margin: 0;
              // margin-left: 2rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  #project {
    & .hero {
      & .container {
        & img {
          // max-width: 100%;
        }
      }
    }

    & .details {
      margin-top: 3rem;

      & .container {
        // display: flex;
        flex-direction: column;

        & .description {
          // max-width: 350px;
          // margin-right: auto;
          // border-top: 1px solid $light-grey;
          // border-bottom: 1px solid $light-grey;
          // padding: 3rem 0;
          // height: max-content;

          // display: grid;
          display: flex;
          flex-direction: column;
          max-width: 100%;
          // grid-template-columns: 1fr;
          // grid-template-rows: 4fr;

          // Project Title
          & h2 {
            // margin-bottom: 1rem;
          }

          // What was learned
          & p {
            // font-size: 15px;
            // margin-bottom: 1.5rem;
            grid-column: 2;
            grid-row: 1 / span 4;
          }

          // Tech Stack
          & span {
            // display: block;
            // color: $slightly-desaturated-cyan;
            // font-size: 16px;
            grid-column: 1;
          }

          & .btn {
            // margin-top: 1.5rem;
            grid-column: 1;
          }
        }

        & .preview {
          // max-width: 625px;
          max-width: 100%;

          & h3 {
            // font-weight: normal;
            // margin-bottom: 2rem;
          }

          & .background {
            & p {
              // font-size: 15px;
            }
          }

          & .preview-images {
            & h3 {
              // margin-top: 3rem;
            }

            & img {
              width: 100%;
              // margin-bottom: 1rem;
            }
          }
        }
      }
    }

    & .prev-next {
      // margin-top: 3rem;

      & .container {
        // display: flex;

        & .link {
          // padding: 3rem 0;
          // width: 50%;
          // display: flex;
          // align-items: center;
          // border-top: 1px solid $light-grey;
          // border-bottom: 1px solid $light-grey;
          flex-direction: column;
          align-items: baseline;

          & svg {
            // margin-right: 2rem;
            margin-bottom: 1rem;
          }

          & h3 {
            // color: $grayish-dark-blue;
          }

          & div {
            // color: rgba($grayish-dark-blue, 0.7);
          }

          &:first-child {
            // border-right: 1px solid $light-grey;
          }

          &:nth-child(2) {
            flex-direction: column-reverse;
            align-items: flex-end;
            a {
              // margin-left: auto;
              // text-align: right;
            }
            & svg {
              // margin: 0;
              // margin-left: 2rem;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
}
