.btn {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 0;
  transition: 0.3s;
  padding: 0;
  display: flex;
  // display: block;
  flex-direction: row;
  // height: 3rem;
  max-width: fit-content;

  &.primary {
    border: none;
    background-color: $dark-blue;

    // & .icon {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   background-color: rgba($black, 0.1);
    //   height: 3rem;
    //   width: 3rem;
    // }

    // & .text {
    color: $very-light-grey;

    padding: 16px 32px;
    // height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // }

    &:hover {
      background-color: $slightly-desaturated-cyan;
    }

    &:disabled {
      background-color: $light-grey !important;
    }
  }

  &.secondary {
    padding: 1rem 2rem;
    border: 1px solid $grayish-dark-blue;
    background-color: very-light-grey;
    color: $grayish-dark-blue;

    &:hover {
      background-color: $grayish-dark-blue;
      color: $very-light-grey;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}
