* {
  box-sizing: border-box;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px;
}

a {
  text-decoration: none;
  line-height: normal;
  //

  &:hover {
    //
  }
}

@media (max-width: 768px) {
  .container {
    max-width: auto;
    margin: 0 auto;
    padding: 0 30px;
  }
}
