.cta {
  margin-top: 7rem;
  margin-bottom: 8rem;

  & .container {
    display: flex;
    align-items: center;

    & h2 {
      max-width: 350px;
    }

    & .line {
      height: 1px;
      background-color: $light-grey;
      flex: 1;
      margin: 0 2rem;
    }
  }
}

@media (max-width: 650px) {
  .cta {
    margin-top: 4rem;
    margin-bottom: 4rem;

    & .container {
      // display: flex;
      // align-items: center;
      flex-direction: column;

      & h2 {
        max-width: 100%;
        text-align: center;
        margin-bottom: 2rem;
      }

      & .line {
        // height: 1px;
        // background-color: $light-grey;
        // flex: 1;
        // margin: 0 1rem;
        display: none;
      }
    }
  }
}
